<template>
  <div class="login-container">
    <h2 class="title-h2">Connexion au Biotag Cloud</h2>
    <form @submit.prevent="checkInput" class="form-1">
      <div class="form-group">
        <label for="email" class="label-login">Adresse e-mail :</label>
        <input type="email" id="email" class="inputemail" v-model="emailInput" required>
      </div>
      <div class="form-group">
        <label for="password"  >Mot de passe :</label>
        <input type="password" id="password" class="inputpassword" v-model="passwordInput" required>
      </div>
	  <div class="liens">
		<button class="button-login" type="submit">Se connecter</button>
		<router-link to="/register">je n'ai pas de compte ></router-link>
	  </div>
    </form>
  </div>
</template>

<script>
	import bcrypt from 'bcryptjs';
	export default {
		name: 'Login',
		data(){
			return {
				passwordInput: '',
				emailInput: '',
				EmailExistenceLength: 0,
				passwordDatabase: '',
				color: 'success',
				user: {
					id: '',
					username: '',
					email: '',
					admin: 'true'
				},
				errors: [],
			}
		},
		
		methods: {
			async checkInput(){
				if(!this.passwordInput || !this.emailInput){
					this.errors.push(" please enter all fields")
					window.alert(this.errors)
					return
				}else{
					const emailChecked = await Promise.all([this.CheckEmailExistence()])
					if(emailChecked){
						if(this.EmailExistenceLength > 0){
							const getPassword = await Promise.all([this.GetPassword()])
							if(getPassword){
								const PasswordTested = await Promise.all([this.TestPassword()])
								if(PasswordTested[0] == true){
									const UserLoaded = await Promise.all([this.LoadUser()])
									if (UserLoaded){
										const TokenGenerated = await Promise.all([this.generateToken()])
										if(TokenGenerated){
											window.alert('Login successfull')
											this.$router.push({name:'tableau-de-bord'})
										}else{
											return
										}
									}else{
										this.errors.push(" Something went wrong.. Please retry")
										window.alert(this.errors)
										return
									}
								}else{
									this.errors.push(" Wrong password")
									window.alert(this.errors)
									return
								}
							}else{
								return
							}
						}else{
							this.errors.push(" User doesn't exist")
							window.alert(this.errors)
							return
						}
					}else{
						return
					}
				}
			},

			async CheckEmailExistence(){
				console.log('email')
				const email = encodeURIComponent(this.emailInput)
				const url = this.$api.getRESTApiUri() + `/check-email-existence/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					console.log(data)
					this.EmailExistenceLength = data.length
					console.log(this.EmailExistenceLength);
				})
				.catch((error) => {
					console.log(error)
				});	
			},

			async GetPassword(){
				const email = encodeURIComponent(this.emailInput)
				const url = this.$api.getRESTApiUri() + `/get-password/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					console.log(data)
					this.passwordDatabase = data[0].password
					console.log(this.passwordDatabase)
				})
				.catch((error) => {
					console.log(error)
				});	
			},

			async LoadUser(){
				const email = encodeURIComponent(this.emailInput);
				const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
				return fetch(url)
				.then(res => res.text())
				.then(async (result) => {
					const data = JSON.parse(result);
						this.user = {
							id: data.user_id,
							email: data.email,
							username: data.username,
							tags_owned: data.tag_attributed,
							admin: data.admin,
							tag_name: data.tag_renamed,
						};
				})
				.catch(error => {
					console.log(error)
				});
				
			},

			async TestPassword(){
				const compare = await bcrypt.compareSync(this.passwordInput, this.passwordDatabase);
				console.log(compare)
				return compare;
			},

			async generateToken(){
				const url = this.$api.getRESTApiUri() + `/generate-token`;
				return fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							user: this.user,
						}),
				})
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					console.log(data)
					console.log(data.accesToken)
					console.log(data.user)
					localStorage.setItem('user', JSON.stringify(data.user))
					localStorage.setItem('accessToken', data.accesToken)
					localStorage.setItem('refreshToken', data.refreshToken)
				});	
			},
			
		}
	}
</script>

<style>
.login-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 80vh;
	background-color: var(--grisclair);
}

.form-1 {
	width: 500px;
	padding: 20px;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.title-h2 {
	color: var(--vertfonce);
	margin-bottom: 20px;
}

.form-group {
	margin-bottom: 20px;	
}

.label-login {
	display: block;
	color: var(--gris);
	margin-bottom: 5px;
}

.inputemail,
.inputpassword {
	width: 100%;
	padding: 10px;
	border: 1px solid var(--grisclair);
	border-radius: 5px;
}

.button-login {
	background-color: var(--vertfonce);
	color: white !important;
	border: none;
	border-radius: 20px;
	padding: 10px 20px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.button-login:hover {
	background-color: var(--turquoise);
}

.liens{
	display: flex;
	justify-content: space-between;
	align-items: end;
}

.liens a{
	color: var(--bleu);
	text-decoration: none;
}

.liens a:hover{
	font-weight: 500;
}
</style>
